import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入jQuery、bootstrap
import $ from 'jquery'
// import 'bootstrap'
// 引入elementUI框架
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"

// 引入bootstrap样式
 import 'bootstrap/dist/css/bootstrap.min.css'
 import 'bootstrap/dist/js/bootstrap.min.js'
// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
//Vue.use(BootstrapVue)

// 全局注册 $
Vue.prototype.$ = $

//全局注册element-ui框架
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    //全局事件总线$bus
    beforeCreate() {
        Vue.prototype.$bus = this;
    },
    render: function (h) {
        return h(App)
    }
}).$mount('#app')