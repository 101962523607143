
<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>


<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
#one:hover {
  border: 1px black solid;
}

#two:hover {
  opacity: 0.4;
  -moz-opacity: 0.8;
  filter: alpha(opacity=40);
}

.btn-lg:hover {
  background-color: white;
  color: #000000;
}
</style>
