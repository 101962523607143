<template>
	<div>
		<div class="container  mt-4 ">
      <p class="text-left border-bottom border-dark" style="font-size: 30px;">甘油碳酸酯（GC）</p>
      
      <p class="mt-2" style="font-size: 18px;text-align: left: ; ;">&nbsp;&nbsp;&nbsp;随着生物柴油的发展，副产物粗甘油的利用成为亟待解决的问题。将甘油利用，制成具有高附加值的甘油碳酸酯成为重要的解决方案。甘油碳酸酯的高附加值来源于其广泛的用途。甘油碳酸酯因其低毒、低蒸发率、低可燃性及高稳定性被认为是一种绿色溶剂，可用于油漆、涂料、聚氨酯泡沫体和化妆品工业。在此背景下，我司以如何开发成本低、收率高、无污染或少污染的方法，制备高质量的甘油碳酸酯展开研究，并取得了卓越的成果。</p>
    </div>

		<!-- 内容2 -->
		<div class="container content-section mt-5">
      <p class="text-left border-bottom border-dark" style="font-size: 30px;">甘油碳酸酯的用途</p>
	  <div class="juzhong">
		<img src="../../public/img/h21.jpg" style="width: 80%" />
	  </div>
      
      <ul>
        <li>用于药物的载体、表面活性剂、去污剂、乳化剂和添加剂</li>
        <li>由于GC本身具有低毒性、低蒸发速率和吸湿保水的特点，所以也被广泛地用于涂料、化妆品和医药等行业；</li>
        <li>GC还是一种多功能的合成分子，由于其结构中同时包含羰基和羟基官能团，可以参与多种衍生化反应，如能与硫、氮和氧等亲核试剂发生亲核取代反应；</li>
        <li>GC脱掉一分子CO2可以得到缩水甘油；</li>
        <li>在锂离子电池中，GC及其衍生物也被用作电解质或溶剂。</li>
        <li>也被广泛应用于食品、纺织、塑料、机械、军事、航空、航天、集成电路、新材料、新能源各个领域； </li>
      </ul>

    </div>

		<!-- 内容3 -->
		<div class="container content-section mt-5">
      
			<p class="text-left border-bottom border-dark" style="font-size: 30px;">甘油碳酸酯（GC）目标市场</p>
        
        <h5><strong>铸造行业</strong></h5>
        <p class="mt-2" style="font-size: 18px;text-align: left: ; ;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;全国铸造行业用碱性酚醛树脂需求量15万吨，按1:0.4的用量来计，约需6万吨GC；GC取代三醋酸甘油酯15%的市场，对GC需求量约9000吨。GC需求量6.9万吨。
        </p>
        
        <h5><strong>环氧树脂行业</strong></h5>
        <p class="mt-2" style="font-size: 18px;text-align: left: ; ;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2020全球环氧树脂产能478万吨，仅中国产能100万吨，按照反应性稀释剂加入量25%计算，需要环状碳酸酯25万吨，其中甘油碳酸酯能够占领10%市场份额，约2.5万吨。
        </p>
        
        <h5><strong>精细化学品工业(涂料行业&聚氨酯行业)</strong></h5>
        <p class="mt-2" style="font-size: 18px;text-align: left: ; ;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021年亚洲船舶涂料市场规模约26.9亿美元，环氧树脂漆和聚氨酯漆等高性能涂料在船舶涂料市场所占份额逐渐增大。2020年我国涂料产量近200万吨，按固含量36%计，约需反应性单体72万吨，其中甲基丙烯酸碳酸甘油酯单体占10%市场，约7.2万吨，以碳酸甘油酯计约有3.5万吨。
        </p>
        <p style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;总体来说，现有市场需求突破12万吨/年，逐步增加市场更是巨大。</p>
    </div>

		<!-- 内容4 -->
		<!-- <div class="container content-section mt-5">
			
    </div> -->
	</div>
</template>

<script>
export default {};
</script>

<style>
body {
	font-family: 'Arial', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: #f8f8f8;
	color: #333;
}

.container {
	margin: 20px auto;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.section-title {
	border-bottom: 2px solid #000;
	padding-bottom: 10px;
	font-size: 30px;
	margin-top: 20px;
}

.tagline {
	font-size: 30px;
	margin-top: 10px;
}

.paragraph {
	font-size: 14px;
	margin-top: 10px;
}

.mission-statement {
	margin-top: 10px;
	font-size: 14px;
}

.content-section {
	border-top: 2px solid #000;
	padding-top: 20px;
	margin-top: 30px;
}

.image-column {
	margin-top: 10px;
}

.content-list {
	list-style-type: none;
	padding-left: 0;
	margin-top: 10px;
}

.index {
	font-weight: bold;
}

.cta-button {
	border-radius: 5px;
	background-color: #000;
	color: #fff;
	width: 300px;
	height: 50px;
	display: inline-block;
	margin-top: 20px;
}

.cta-button a {
	color: #fff;
	text-decoration: none;
}

.cta-button:hover {
	background-color: #333;
}
</style>
