<template>
  <div class="d-flex align-items-center justify-content-between"
      style="position:relative; height: 80px; background-color: #000000">
    <!-- 头部01 -->
    <div class="align-items-center justify-content-between ml-4">
      <img src="./img/logo.jpg" style="width: 50%;"/>
    </div>

    <!--头部02-->
    <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);">
        <div
          class="
            container
            d-flex
            align-items-center
            justify-content-around
            m-auto"  
            style="background-color: #000000;"
        >
          <!-- <ul class="btn-group nav list-unstyled row"> -->
          <ul class="nav nav-pills">
            <li class="nav-item pr-4 ">
              <router-link to="/demo1" class="btn text-white nav-link">
                产品特性
                </router-link>
            </li>
            <li class="nav-item pr-4">
              <router-link to="demo2" class="btn text-white nav-link">
                用途和应用
                </router-link>
            </li>
            <li class="nav-item pr-4">
              <router-link to="demo3" class="btn text-white nav-link">
                催化剂
                </router-link>
            </li>
            <li class="nav-item pr-4">
              <router-link to="demo4" class="btn text-white nav-link">
                生产企业
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      
  </div>
</template>

<script>
export default {
methods:{
  //跳转至购物车界面的路由
  routerJum: function (name) {
    this.$router.push({
      name:name
    })
  },
}
}
</script>

<style scoped>
a {
text-decoration: none;
}
.router-link-active {
text-decoration: none;
}
.el-badge__content.is-fixed {
position: absolute;
top: -2px;
right: 1px;
}
</style>