<template>
    <div class="text-white" style="background-color: #000000; height:380px">
        <div class="text-white" style="background-color: #000000;display: flex;display: -webkit-flex;">
            <div style="flex:1;height: 200px;border:1px solid black;margin-left: 100px;margin-top:50px">
                <ul class="list-unstyled" style="width:200px">
                    <li style="margin:10px"><a href="javascript:;" class="text-white">组织机构</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">企业文化</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">公司荣誉</a></li>
                </ul>
            </div>
            <div style="flex:1;height: 200px;border:1px solid black;margin:auto;margin-top:50px">
                <ul class="list-unstyled">
                    <li style="margin:10px"><a href="javascript:;" class="text-white">获得帮助</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">联系我们</a></li>
                </ul>
            </div>
            <div style="flex:1;height: 200px;border:1px solid black ;margin:auto;margin-top:50px">
                <ul class="list-unstyled">
                    <li style="margin:10px"><a href="javascript:;" class="text-white">关于企业</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">新闻</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">投资者</a></li>
                    <li style="margin:10px"><a href="javascript:;" class="text-white">招贤纳士</a></li>
                </ul>
            </div>
            
            <div style="flex:1;height: 200px;border:1px solid black;margin-left:200px;margin-right:10px;margin-top:50px">
                <div><img src="./img/002.png" width="50px"/></div>
                <div><img src="./img/wx.jpg" width="100px"/></div>
            </div>
        </div>   
        <div style="margin-top:50px;margin-left:100px;">
            <p><img src="./img/003.png" width="25px"/>&nbsp;&nbsp;&nbsp;中国大陆</p>
        </div>  
    </div>
</template>

<script>
export default {};
</script>

<style>
    a{
        text-decoration:none
    }
</style>