<template>
    <div>
        <div class="container  mt-4 ">
            <p class="text-left border-bottom border-dark" style="font-size: 30px;text-align: center;">均相催化剂</p>
                <div class="container">
                        <img src="../../public/img/h31.jpg" class="centered-image" alt="Image">
                </div>
        </div>
    <!-- 内容2 -->
        <div class="container  mt-4 ">
            <p class="text-left border-bottom border-dark" style="font-size: 30px;text-align: center;">非均相催化剂—离子液体催化剂</p>
                <div class="container">
                        <img src="../../public/img/h32.jpg" class="centered-image" alt="Image">
                </div>
        </div>
    <!-- 内容3 -->
        <div class="container  mt-4 ">
            <p class="text-left border-bottom border-dark" style="font-size: 30px;text-align: center;">非均相催化剂—分子筛催化剂</p>
                <div class="container">
                        <img src="../../public/img/h33.jpg" class="centered-image" alt="Image">
                </div>
        </div>
    <!-- 内容4 -->
        <div class="container  mt-4 ">
            <p class="text-left border-bottom border-dark" style="font-size: 30px;text-align: center;">非均相催化剂—金属氧化物催化剂</p>
                <div class="container">
                        <img src="../../public/img/h34.jpg" class="centered-image" alt="Image"><br><br>
                        <img src="../../public/img/h35.jpg" class="centered-image" alt="Image"><br><br>
                        <img src="../../public/img/h35.jpg" class="centered-image" alt="Image">
                </div>
        </div>
    </div>
  </template>
  
  
  
  
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .container {
      text-align: center;
      margin-top: 20px; /* 适当调整顶部外边距 */
    }

    .centered-image {
      display: inline-block;
      max-width: 100%;
    }
  </style>