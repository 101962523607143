<template>
    <div>

      <img src="../../public/img/lb1.jpg" style="width: 100%" />
      <hr>
      <!-- <化学与物理性质> -->
      <div class="container  mt-4 ">
        <p class="text-left border-bottom border-dark" style="font-size: 30px;">化学与物理性质  <em class="texxt-muted" style="font-size: 20px;"></em><br></p>
        <div class="juzhong">
          <img src="../../public/img/h16.jpg" style="width: 80%" />
        </div>
        
        <p style="text-align: center;color: #0b85da; font-size: 18px;">碳酸甘油酯是一种生物基专用化学品、用途广泛、市场前景广阔。</p>
      </div>
      
  
      <!--甘油与尿素制备甘油碳酸酯技术-->
      <div class="container  mt-4 ">
        <p class="text-left border-bottom border-dark" style="font-size: 30px;">甘油与尿素制备甘油碳酸酯技术</p>
        <div class="juzhong">
          <img src="../../public/img/h15.jpg" style="width: 80%" /><br><br>
        </div>

        
      </div>

      <!-- <其他合成方案> -->
      <div class="container  mt-4 ">
        <p class="text-left border-bottom border-dark" style="font-size: 30px;">其他合成方案</p>
        <ul>
            <li>1、光气法：以甘油与光气为原料在NaOH等碱性催化剂作用下合成得到碳酸甘油酯。</li>
            <li>2、一氧化碳氧化羰化法：以甘油与CO为原料，以O2等为氧化剂，在高温（或高压）条件下经催化反应生成碳酸甘油酯。</li>
            <li>3、二氧化碳羰化法：以CO2和甘油为原料在一定条件下直接反应生成碳酸甘油酯。</li>
            <li>4、酯交换法：在适当的反应条件或催化作用下，利用甘油与链状碳酸酯或环状碳酸酯发生酯交换反应，生成碳酸甘油酯和醇。</li>
          </ul>
        <div class="juzhong">
          
        <img src="../../public/img/h11.jpg" style="width: 80%" />
        </div>
        
        <br><br><br><br>
        <p class="text-left border-bottom border-dark" style="font-size: 30px;">方案对比</p>
        <div class="row">
          <div class="col-md-4">
            <img src="../../public/img/h12.jpg" class="img-fluid" alt="Image">
          </div>
          <div class="col-md-6" style="width: 60%;margin-top: 20px;">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;相比之下，氨酯法不仅能够利用副产物甘油，而且能够提高尿素附加值，同时副产物氨气又能够与二氧化碳重新合成尿素，进而形成一个以氨为中间媒介的回收二氧化碳的反应体系，其本质上是通过不断地消耗甘油和二氧化碳得到甘油碳酸酯，该方法反应条件温和，绿色清洁，经济效益高，是一种工业应用较为广泛的合成方法。 于是我们采用便宜的甘油和尿素这条绿色经济的路线，通过技术创新点—自制催化剂，合成高附加值产物甘油碳酸酯（产物氨气还能和CO2反应达到节能减排的效果），来解决市场甘油碳酸酯产率低需求量大的问题，同时甘油碳酸酯下游产业众多，甘油碳酸酯及其衍生物价值更高。</p>
          </div>
        </div>
      </div>
  
  
      
  
    </div>
  </template>
  
  <script>
  export default {
    name: "demo1",}
  </script>
  
  <style lang="less">
  .juzhong {
      text-align: center;
      margin-top: 20px; /* 适当调整顶部外边距 */
    }
  </style>
  