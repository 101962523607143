import Vue from "vue";
import Router from "vue-router";

import demo1 from '../views/demo1.vue';
import demo2 from "../views/demo2.vue";
import demo3 from "../views/demo3.vue";
import demo4 from "../views/demo4.vue";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/demo1", name: "demo1", component: demo1 },
    { path: "/demo2", name: "demo2", component: demo2 },
    {
      path: "/demo4",
      name: "demo4",
      component: demo4
    },
    
    {
      path: "/demo3",
      name: "demo3",
      component: demo3
    },
    {
      path: '*',
      redirect: "/demo1"
    },
    {
      path: '/demo1',
      redirect: "/demo1"
    },
    {
      path: '/demo4',
      redirect: "/demo4"
    },
  ]
});


