<template>
	<div>
		<div class="container text-center mt-4">
			<p class="section-title font-size-30">河南“甘”勇当先绿色能源有限责任公司</p>
			<p class="tagline mt-3 font-size-30">“甘”勇当先</p>
			<p class="paragraph font-size-14 mt-3">
				河南“甘”勇当先绿色能源有限责任公司是一家筹建中的高新技术企业，它以技术开发为导向，主要致力于绿色化学合成工艺创新以及甘油碳酸酯及其衍生物的研发、生产和销售。
			</p>
			<p class="paragraph font-size-14 mt-3">
				<strong>公司文化</strong>:绿色科技创造全新未来的理念，人才、创造、绿色、分享、责任是公司文化的五大基石。
			</p>
		</div>

		<!-- 内容2 -->
		<div class="container content-section mt-5">
			<div class="row mt-4">
				<div class="col image-column mt-3">
					<img src="../../public/img/h43.jpg" alt="NIKE Image" style="max-width: 100%; height: auto;">
				</div>
				<div class="col">
					<table class="content-list list-unstyled mt-3 font-size-13">
						<tbody>
							<tr>
								<td><strong>公司使命</strong></td>
							</tr>
							<tr>
								<td>&nbsp;&nbsp;&nbsp;公司将在绿色能源领域持续创新，引领行业发展方向，不断提供贴合客户需求的高质量产品，为化工行业做出贡献，为人类创造美好生活。</td>
							</tr>
							&nbsp;
							<tr>
								<strong><td>公司愿景</td></strong>
							</tr>
							<tr>
								<td>&nbsp;&nbsp;&nbsp;务实基础，创新发展，创建受社会尊敬，让员工自豪，国际一流的绿色能源公司。</td>
							</tr>
							&nbsp;
							<tr>
								<strong><td>公司核心价值观</td></strong>
							</tr>
							<tr>
								<td>&nbsp;&nbsp;&nbsp;务实创新，追求卓越，客户导向，绿色化工，感恩奉献，团队致胜。</td>
							</tr>
							
						</tbody>
					</table>
					<!-- <button class="cta-button rounded bg-dark color-white font-size-15 mt-4">
						<a href="index.html" class="text-white">查看更多NIKE产品</a>
					</button> -->
				</div>
			</div>
		</div>

		<!-- 内容3 -->
		<div class="container content-section mt-5">
			<div class="row mt-4">
				<div class="col image-column mt-4">
					<table class="content-list list-unstyled mt-3 font-size-13">
						<tbody>
							<tr>
								<td>&nbsp;&nbsp;&nbsp;本公司从<strong>个人、团队、公司、社会</strong>四大层面去诠释公司文化：本公司尊重人才、重视人才，将人才摆在公司文化的首要地位；本公司提倡创新思维，善于发现生活者难题与需要，将创新融入生活者的需求中，同时以高新科技致力于环境保护，营造绿色科技；本公司内部倡导员工乐于分享，公平竞争，团结互助，才能高效完成公司目标；责任是公司文化提出的重要概念，本公司不仅把自己定位一个盈利组织，同时更把自己定位为一个社会公民，以公司为主体作为社会公民，承担相应的责任与使命。</td>
							</tr>
						</tbody>
					</table>
					
				</div>
				<div class="col image-column mt-4">
					<img src="../../public/img/h42.jpg" alt="NIKE Image" style="max-width: 100%; height: auto;">
				</div>
			</div>
		</div>

		<!-- 内容4 -->
		<div class="container content-section mt-5 mb-5">
			<div class="row mt-4">
				<div class="col image-column mt-4">
					<img src="../../public/img/h41.jpg" alt="NIKE Image" style="max-width: 100%; height: auto;">
				</div>
				<div class="col image-column mt-4">
					
					<table class="content-list list-unstyled mt-3 font-size-13">
						<tbody>
							<tr>
								<td>河南“甘”勇当先绿色能源有限责任公司定位于<strong>高纯度甘油碳酸酯的研发、生产和销售一体化</strong>的化工企业，未来发展将涵盖以甘油碳酸酯为原料的聚酯、聚碳酸酯、聚氨酯、聚酰胺、表面活性剂等精细化工产品，并争取企业效益与生态效益共存，社会服务与国家宏观政策一致，致力于打造国内一流的化工企业。</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
body {
	font-family: 'Arial', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: #f8f8f8;
	color: #333;
}

.container {
	margin: 20px auto;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.section-title {
	border-bottom: 2px solid #000;
	padding-bottom: 10px;
	font-size: 30px;
	margin-top: 20px;
}

.tagline {
	font-size: 30px;
	margin-top: 10px;
}

.paragraph {
	font-size: 14px;
	margin-top: 10px;
}

.mission-statement {
	margin-top: 10px;
	font-size: 14px;
}

.content-section {
	border-top: 2px solid #000;
	padding-top: 20px;
	margin-top: 30px;
}

.image-column {
	margin-top: 10px;
}

.content-list {
	list-style-type: none;
	padding-left: 0;
	margin-top: 10px;
}

.index {
	font-weight: bold;
}

.cta-button {
	border-radius: 5px;
	background-color: #000;
	color: #fff;
	width: 300px;
	height: 50px;
	display: inline-block;
	margin-top: 20px;
}

.cta-button a {
	color: #fff;
	text-decoration: none;
}

.cta-button:hover {
	background-color: #333;
}
</style>
